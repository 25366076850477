<template>
    <div class="showcase pb-3" v-if="products.length">
        <div class="showcase-products mb-4 pt-4 pl-1 pr-1">
            <div class="title text-center"> {{ name }}</div>
        </div>
        <b-container>
            <b-row class="mx-n2">
                <b-col cols="6" :md="$wdconfig.modules.product.size_in_listing === 'large' ? 4 : 3" class="mb-3 px-2" v-for="product in products" :key="product.code">
                    <Product :product="product"/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import Product from '~/components/Product'

    export default {
        components: {
            Product
        },
        props: {
            id: {
                default: 0
            },
            name: {
                default: ''
            },
            products: {
                default: () => []
            }
        }
    }
</script>
